<template>
	<div class="partial partial--highlights highlights" :style="pageStyles">
		<slideshow
			:slideCount="slideCount"
			:sectionLabel="`Business Highlights`"
			:subsectionLabel="content.subsectionLabel"
			:slideActive="slideActive"
			@slideActive="setSlideActive"
			>
			<template v-slot:body>
				<div class="highlights__wrapper">
					<div class="highlights__col">
						<div class="highlights__subtitle">
							{{ content.subtitle }}
						</div>
						<div class="highlights__title">
							{{ content.title }}
						</div>
						<div class="highlights__content" v-html="content.content"></div>
					</div>

					<div class="highlights__col">
						<div class="highlights__quote">
							&ldquo;{{ content.quote }}&rdquo;
						</div>

						<div class="highlights__author">
							<img :src="require('@/assets/business-highlights/' + content.authorImg)" class="highlights__author-img" :alt="content.author" />

							<div>
								<div class="highlights__author-name">
									{{ content.author }}
								</div>
								<div class="highlights__author-title" v-html="content.authorTitle"></div>
							</div>
						</div>

					</div>
				</div>
			</template>

			<template v-slot:actions>
				<a :href="content.link" class="btn btn--primary btn--outline">Learn More</a>
			</template>
		</slideshow>
	</div>
</template>

<script>
import Slideshow from '@/components/Slideshow'

export default {
	name: 'PartialHighlights',
	components: {
		Slideshow,
	},
	data: () => ({
		slideActive: 0,
	}),
	computed: {
		slides() {
			const content = require(`@/assets/content/highlights/filler.json`)
			return content.slides || []
		},
		slideCount() {
			return this.slides.length
		},
		content() {
			return this.slides[this.slideActive]
		},
		pageStyles() {
			let img
			try {
				img = require('@/assets/business-highlights/'+ this.content.bgImg)
			} catch (error) {
				console.warn(error)
			}
			return {
				backgroundColor: this.content.bgColor,
				backgroundImage: `url(${img})`
			}
		},
	},
	methods: {
		setSlideActive(active) {
			const slide = this.slides[active]
			this.$router.push({ params: { slide: slide.id }})
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(val) {
				const { slide } = val.params
				const slideIdx = this.slides.findIndex(s => s.id == slide) || 0

				this.slideActive = slideIdx
			},
		},
	},
}
</script>

<style scoped lang="scss">
.highlights {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&__wrapper {
		display: flex;
		flex-direction: column;

		@include media-query-min-width('tablet') {
			flex-direction: row;
		}
	}

	&__subtitle {
		@include fontSize(24px);
		@include gutter('margin-bottom', .75);
		font-family: $font-family-secondary;
		letter-spacing: 1px;
		color: color_('primary', 'text');
		text-transform: uppercase;
		margin: 0;
	}

	&__title {
		@include fontSize(40px);
		@include gutter('margin-bottom', 1.25);
		color: color_('primary', 'text');
		font-family: $font-family-secondary;
		font-weight: bold;
		margin-top: 0;
		text-transform: uppercase;
		line-height: .9;

		@include media-query-min-width('tablet') {
			@include fontSize(72px);
		}
	}

	&__col {
		@include gutter('padding-top', .5);
		@include gutter('padding-bottom', .5);
		flex: 1 1 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-query-min-width('tablet') {
			@include gutter('padding-left', 1.5);
		}


		@include media-query-min-width('desktop') {
			@include gutter('padding-right', 2);
			@include gutter('padding-left', 2);
		}

		&:first-of-type {
			@include gutter('padding-right', 1.5);
			@include gutter('padding-left', 0);
			border-right: 0;
			border-bottom: 1px solid rgba(color_('primary', 'text'), .5);

			@include media-query-min-width('tablet') {
				border-bottom: 0;
				border-right: 2px solid rgba(color_('primary', 'text'), .5);
			}
		}
	}

	&__content {
		@include fontSize(18px);

		::v-deep ul {
			margin: 0;
			padding-left: 1em;

			li {
				@include gutter('margin-bottom', .5);
				line-height: 1.5;
			}
		}
	}

	&__quote {
		@include fontSize(18px);
		@include gutter('margin-bottom', 1.25);
		line-height: 1.5;

		@include media-query-min-width('tablet') {
			@include fontSize(24px);
		}
	}

	&__author {
		line-height: 1.5;
		display: flex;
		align-items: center;

		&-img {
			@include gutter('margin-right', .75);
			width: 72px;
			height: auto;

			@include media-query-min-width('tablet') {
				width: 106px;
			}
		}

		&-name {
			@include fontSize(18px);
			font-weight: bold;
		}

		&-title {
			@include fontSize(14px);
			color: rgba(color_('primary', 'text'), .5);
		}
	}
}
</style>
